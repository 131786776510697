<!-- 个人中心--我的订单--订单详情 -->
<template>
  <div class="usercenter">
    <usercenterhr class='usercenterhr'></usercenterhr>
    <usercenterlt class='usercenterlt' :nav='1'></usercenterlt>
    <orderdetailtitle class='orderdetailtitle'></orderdetailtitle>
    <detailstep v-if='orderData&&orderType<4&&orderType!=5' class='detailstep' :type='orderType' :popData='orderData'></detailstep>
    <ordertypes v-if='orderData' :popData='orderData'  class='ordertypes' :type='orderType' @exportTyps='exportTyps'></ordertypes>
    <logisticsinfo class='logisticsinfo' v-if='orderType!=4&&orderType!=5&&orderData' :popData='orderData' :type='orderType' ></logisticsinfo>
    <orderdetailinfo v-if='orderData' :popData='orderData' class='orderdetailinfo' :type='orderType'></orderdetailinfo>
    <orderproductinfo v-if='orderData' :popData='orderData' class='orderproductinfo' :type='orderType' ></orderproductinfo>
  </div>
</template>
<script>
import usercenterhr from '@/components/usercenter-hr/usercenter-hr.vue';//顶部hr
import usercenterlt from '@/components/usercenter-lt/usercenter-lt.vue';//左侧栏目
import orderdetailtitle from '@/components/orderdetail-title/orderdetail-title.vue';//订单详情-标题
import detailstep from '@/components/detailstep/detailstep.vue';//订单详情-状态步骤
import ordertypes from '@/components/ordertypes/ordertypes.vue';//订单详情-状态
import orderdetailinfo from '@/components/orderdetailinfo/orderdetailinfo.vue';//订单详情-货物状态表格
import orderproductinfo from '@/components/orderproductinfo/orderproductinfo.vue';//订单详情-产品状态表格
import logisticsinfo from '@/components/logisticsinfo/logisticsinfo.vue';//物流信息
export default {
  name: 'usercenter',
  components: {
    usercenterhr,
    usercenterlt,
    orderdetailtitle,
    detailstep,
    ordertypes,
    orderdetailinfo,
    orderproductinfo,
    logisticsinfo
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'订单详情-微微定',
      orderType:0,//订单状态0~4，待支付~已取消
      orderData:null,//订单数据
    }
  },
  props: {
    msg: String
  },
  methods:{
    exportTyps(){
      // 取消订单成功
      this.orderType=4
    },
    localReset(){
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainOrderDeail?OrderName='+this._spf(this.$route.query.OrderName),this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              if(res.data.Data){
                this.orderData = res.data.Data;//数据
                // 状态
                if(res.data.Data.PayStatusID==0&&res.data.Data.StatusID!=7){
                  this.orderType=0
                }else{
                  if(res.data.Data.StatusID==2){
                    this.orderType=3
                  }
                  if(res.data.Data.StatusID==7){
                    this.orderType=4
                  }
                  if(res.data.Data.StatusID<2&&res.data.Data.ISDelivery==0){
                    this.orderType=1
                  }
                  if(res.data.Data.StatusID<2&&res.data.Data.ISDelivery!=0){
                    this.orderType=2
                  }
                  if(res.data.Data.StatusID==8||res.data.Data.StatusID==9){
                    this.orderType=5
                  }
                }
              }
            }
          }, res => {
            console.log('异常')
          })
      },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b,_pomise.c])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.localReset()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.usercenter {
  width: 1200px;margin:0 auto;zoom:1;padding-bottom:20px;
  .usercenterhr {
    width: 1200px;margin:0 auto;margin-top:20px;margin-bottom:10px;position: relative;z-index: 9;
  }
  .usercenterlt {
    float:left;width: 180px;margin-top:-10px;
  }
  .orderdetailtitle {
    float:right;width: 1010px;margin-top:-10px;margin-bottom:1px;
  }
  .detailstep {
    float:right;width: 1010px;margin-bottom:1px;
  }
  .ordertypes {
    float:right;width: 1010px;
  }
  .orderdetailinfo {
    float:right;width: 1010px;
  }
  .orderproductinfo {
    float:right;width: 1010px;
  }
  .logisticsinfo {
    float:right;width: 1010px;
  }
  &:after {
    content: "";display: block;clear:both;
  }
}
</style>
