<template>
  <div class="orderdetailinfo" v-if='selfData&&type!=4' >
    <table>
      <tr>
        <th v-if='type==4'>订单信息</th>
        <th v-if='type!=4'>收货人信息</th>
        <th>配送信息</th>
        <th v-if='type!=4'>付款信息</th>
        <th v-if='type==4'>退款信息</th>
      </tr>
      <tr>
        <td>
          <div  v-if='type!=4' class="info">
            <div class="group-info">
              <div class="lt">收货人：</div>
              <div class="rt">{{selfData.ContactName}}</div>
            </div>
            <div class="group-info">
              <div class="lt">地址：</div>
              <div class="rt">{{selfData.ShippingAddress}}</div>
            </div>
            <div class="group-info">
              <div class="lt">手机号码：</div>
              <div class="rt">{{selfData.ContactPhone}}</div>
            </div>
          </div>
          <div  v-if='type==4' class="info">
            <div class="group-info">
              <div class="lt">支付方式：</div>
              <div class="rt">在线支付</div>
            </div>
            <div class="group-info">
              <div class="lt">取消时间：</div>
              <div class="rt">2019-10-24 10:46:06</div>
            </div>
            <div class="group-info">
              <div class="lt">取消原因：</div>
              <div class="rt">过期未付款</div>
            </div>
          </div>
        </td>
        <td>
          <div class="info">
            <div class="group-info">
              <div class="lt">配送方式：</div>
              <div class="rt">{{selfData.ShippingMethodName?selfData.ShippingMethodName:'--'}}</div>
            </div>
            <div class="group-info">
              <div class="lt">运费：</div>
              <div class="rt">&yen;{{selfData.TransportAmount}}</div>
            </div>
          </div>
        </td>
        <td>
          <div  v-if='type!=4' class="info">
            <div class="group-info">
              <div class="lt">付款方式：</div>
              <div class="rt">{{selfData.PaymentType}}</div>
            </div>
            <div class="group-info">
              <div class="lt">商品总额：</div>
              <div class="rt">&yen;{{selfData.ProductAmount}}</div>
            </div>
            <div class="group-info">
              <div class="lt">运费：</div>
              <div class="rt">&yen;{{selfData.TransportAmount}}</div>
            </div>
            <div class="group-info">
              <div class="lt">优惠券：</div>
              <div class="rt">-&yen;{{selfData.DiscountAmount}}</div>
            </div>
            <div class="group-info" v-if='selfData.PayStatusID==0'>
              <div class="lt">
                应付金额：
              </div>
              <div class="rt">&yen;{{selfData.PayAmount}}</div>
            </div>
            <div class="group-info" v-if='selfData.PayStatusID!=0'>
              <div class="lt">
                已付金额：
              </div>
              <div class="rt">&yen;{{selfData.PayedAmount}}</div>
            </div>
          </div>
          <div v-if='type==4' class="info">
            <p class="cancelp">您的订单已取消，所有优惠券将返还至您的微微定账户</p>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: 'orderdetailinfo',
  data(){
    return {
      selfData:null,//数据本地
    }
  },
  props: {
    type: Number,
    popData:Object,
  },
  methods:{
  },
  mounted(){
    this.$nextTick(()=>{
      if(this.popData){
        this.selfData = this.popData
      }
    })
  }
}
</script>
<style  lang="scss" scoped>
.orderdetailinfo {
  background: #fff;padding-bottom:30px;
  table {
    width: 970px;margin:0 auto;border-collapse: collapse;border:1px solid #eeeef0;
    tr {
      th {
        background: #faf9fe;color:#818189;height: 50px;font-size: 14px;
      }
      td {
        // height: 170px;
        border-right:1px solid #eeeef0;width: 30%;position: relative;padding:10px 0px;
        .info {
          // position: absolute;top:20px;left:80px;padding-right: 80px;
          .group-info {
            zoom:1;margin-bottom:10px;width: 190px;margin:0 auto;margin-bottom:5px;
            .lt {
              float:left;width: 70px;font-size: 12px;color:#b7b7b7;text-align: left;
            }
            .rt {
              float:right;width: 120px;color:#383838;font-size: 12px;
            }
            &:after {
              content: '';display: block;clear: both;
            }
            &:last-child {
              margin-bottom:0px;
            }
          }
          .cancelp {
            color:#b7b7b7;
          }
        }
        &:nth-child(1) {
          width: 40%;
          // .info {
          //   left:80px;padding-right: 80px;
          // }
        }
        &:nth-child(2) {
          // .info {
          //   left:80px;padding-right: 80px;
          // }
        }
        &:nth-child(3) {
          // .info {
          //   left:80px;padding-right: 80px;
          // }
        }
      }
    }
  }
}
</style>