<!-- 订单详情-商品信息表格 -->
<template>
  <div class="orderproductinfo" v-if='selfData'>
    <table>
      <tr>
        <th>商品</th>
        <th>数量</th>
        <th>金额（元）</th>
        <th>操作</th>
      </tr>
      <tr v-for='item,index in selfData.orderDeailJobList'>
        <td>
          <div class="box">
            <div class="img" @click='linkdetail(item)'>
              <img :src="item.ImageUrl | imgsrc(that)">
            </div>
            <div class="info">
              <h6 @click='linkdetail(item)'>{{item.JobName | jobnames(0)}}</h6>
              <p>数量：{{item.DzproductnumberName}}</p>
            </div>
            <div class="info1">
              <p>规格：{{item.DzspecificationName}}</p>
              <p>材质：{{item.DzmaterialName}}</p>
            </div>
          </div>
        </td>
        <td>
          <p class="num">x{{item.JobNum}}</p>
        </td>
        <td>
          <p class="price">&yen;{{item.Price}}</p>
        </td>
        <td :rowspan="selfData.orderDeailJobList.length" v-if='index==0'>
          <div class="handle">
            <div class="lt">
              <button v-if='type!=0'  @click='buyPro(selfData)' class="buyagain">再次购买</button>
              <p class="num" v-if='type==0'>--</p>
            </div>
            <div class="rt">
              <div class="tddiv" v-for='items,indexs in selfData.orderDeailJobList' :key='indexs'>
                <div class="tddivs">
                  <button class="mgbtm" v-if='selfData.PaymentTypeID!=6&&selfData.PayStatusID===2&&selfData.StatusID!=2&&selfData.StatusID!=7&&selfData.PaymentTypeID!=6' @click='applyafter(selfData,items)'><svg><use xlink:href="#iconshouhouguanli"></use></svg>申请售后</button>
                  <template v-else-if='selfData.StatusID!=2'>--</template>
                  <template v-if='selfData.StatusID==2'>
                    <button v-if='items.isproductEvalute==0' class="nfk" @click='rmkbtm(items,selfData.OrderName)'>评价</button>
                    <template v-else>--</template>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>
    <div class="detail">
      <div class="group-info">
        <div class="rt">
          &yen;{{selfData.ProductAmount}}
        </div>
        <div class="lt">
          商品总额：
        </div>
      </div>
      <div class="group-info">
        <div class="rt">
          &yen;{{selfData.TransportAmount}}
        </div>
        <div class="lt">
          运费：
        </div>
      </div>
      <div class="group-info" v-if='selfData.PayStatusID==0'>
        <div class="rt">
          &yen;{{selfData.PayAmount}}
        </div>
        <div class="lt">
          应付总额：
        </div>
      </div>
      <div class="group-info" v-if='selfData.PayStatusID!=0'>
        <div class="rt">
          &yen;{{selfData.PayedAmount}}
        </div>
        <div class="lt">
          已付总额：
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'orderproductinfo',
  data(){
    return {
      selfData:null,
      that:this
    }
  },
  props: {
    type:Number,
    popData:Object
  },
  methods:{
    linkdetail(item){
      // 跳转详情
      var _idcx = item.DynamicId?item.DynamicId:''
      // 跳转详情
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id::res.data.Data.producttypeid,idcx:'',detailType:1}})
        var id = item.JobSubsTypesId + '.html'
        this._open({path:'/predetail',query:{idcx:_idcx,id:id,}})
      }})
    },
     rmkbtm(item,v){
      this._router({then:()=>{
        this.$router.push({path: "/remarkinfo?ProductType="+item.ProductType+'&orderName='+v+'.html'})
        // this._open({path: "/remarkinfo?ProductType="+item.ProductType})
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:"/remarkinfo?ProductType="+item.ProductType+'&orderName='+v+'.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    applyafter(list,item){
      // console.log(list,item)
      this._axios({then:()=>{
        // 申请售后
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/CheckRefundApply?OrderName='+list.OrderName+'&type=2'+'&ProductId='+item.ProductType,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              if(!res.data.Data.RefundApplyId){
                this._router({then:()=>{
                  this.$router.push({path:"/applyafter?ProductId="+item.ProductType+'&OrderName='+list.OrderName+'.html'})
                  // this._open({path: "/applyafter"})
                },exp:true,catch:()=>{
                  this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
                  // this._open({path: "/accountlogin",query: {redirect:'/'}})
                }})
              }else{
                // 已经在申请售后跳转申请售后审核或结果
                this._axios({then:()=>{
                  this.axios.post(process.env.VUE_APP_URL+'UserCenter/RefundApplyPage?id='+res.data.Data.RefundApplyId,this.keys())
                  .then(res => {
                      if(res.data.Success){
                        if(res.data.Data.RefundType===1){
                          this._router({then:()=>{
                            this.$router.push({path:"/applyaftercause?ProductId="+item.ProductType+'&OrderName='+list.OrderName+'.html'})
                          },exp:true,catch:()=>{
                            this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
                            // this._open({path: "/accountlogin",query: {redirect:'/'}})
                          }})
                        }else if(res.data.Data.RefundType===2){
                          this._router({then:()=>{
                            this.$router.push({path:"/returngood?ProductId="+item.ProductType+'&OrderName='+list.OrderName})
                          },exp:true,catch:()=>{
                            this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
                            // this._open({path: "/accountlogin",query: {redirect:'/'}})
                          }})
                        }
                      }else{
                        this.$MessageBox.alert(res.data.Error,{type:'error'});
                      }
                    }, res => {
                      // this.$MessageBox.alert('系统异常',{type:'error'});
                    })
                },exp:true,catch:()=>{
                      this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
                      // this._open({path: "/accountlogin",query: {redirect:'/'}})
                }})
                
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
        }, res => {
          // this.$MessageBox.alert('系统异常',{type:'error'});
        })
      },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    buyPro(item){
      // 再次购买
      // console.log(item)
      // return;
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/AgainBuy?orderName='+item.OrderName,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              // 返回购物车
              // console.log(this.buylist)
              for(let i in res.data.Data){
                res.data.Data[i].id = res.data.Data[i].jobID
                res.data.Data[i]._choice = true
              }
              window.sessionStorage.setItem('carlist',JSON.stringify(res.data.Data))
              setTimeout(()=>{
                this._router({exp:false,catch:()=>{
                  // this.$router.push({path:'accountlogin',query:{redirect:'buycar'}})
                  // this._open({path: "/accountlogin",query: {redirect:'buycar'}})
                },then:()=>{
                  this.$router.push('/buycar.html')
                }})
              },500)
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'warning'});
            }
          }, res => {
            console.log('再次购买异常')
          })
      },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
    },
  },
  mounted(){
    this.$nextTick(()=>{
      if(this.popData){
        
      this.selfData = this.popData
      }
    })
  },
  filters:{
    imgsrc(v,that){
      return that._src + v;
    },
    jobnames(v,i){
      // 处理名称
      var _str = v.indexOf('[');
      var _end = v.indexOf(']');
      var _s = v.substring(_str+1,_end)
      var _name =v.substring(0,_str)
      var _number = _s.split('-')[2]
      var _tip = _s.split('-')[1]
      var _tip1 = _s.split('-')[0]
      if(i==0){
        return _name
      }
      if(i==1){
        return _number
      }
      if(i==2){
        return _tip
      }
      if(i==3){
        return _tip1
      }
    },
  }
}
</script>
<style  lang="scss" scoped>
.orderproductinfo {
  background: #fff;min-height: 290px;
  table {
    border:1px solid #ededed;width: 970px;margin:0 auto;border-collapse: collapse;
    tr {
      border-bottom:1px solid #ededed;
      th {
        background: #faf9fe;height: 50px;color:#818189;font-size: 14px;
      }
      td {
        height: 110px;border-right:1px solid #ededed;
        .box {
          display: flex;justify-content: center;align-items: flex-start;
          .img {
            margin-right: 20px;width: 66px;cursor: pointer;
            img {
              display: block;width: 66px;height: 66px;
            }
          }
          .info {
            margin-right: 20px;
            h6 {
              width: 150px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp:2;line-clamp:2;-webkit-box-orient:vertical;cursor: pointer;
            }
            p {
              color:#8d8d8d;margin-top:5px;
            }
          }
          .info1 {
            p {
              color:#8d8d8d;width: 180px;
            }
          }
        }
        .price {
          color:#c01d20;font-weight: bold;text-align: center;
        }
        .num {
          text-align: center;
        }
        .buyagain {
          display: block;margin:0 auto;background: none;width: 75px;height: 25px;font-size: 14px;border:1px solid #c01d20;color:#c01d20;border-radius: 5px;
          &:hover {
            opacity: 0.8;
          }
        }
        &:first-child {
          width: 450px;
        }
        .handle {
          display: flex;justify-content: center;height: 100%;
          .lt {
            border-right:1px solid #ededed;display: flex;justify-content: center;align-items: center;width: 100%;
            // button {
            //   margin:0 5px;
            // }
          }
          .rt {
            width: 100%;
            .tddiv {
              height: 111px;display: flex;justify-content: center;align-items: center;width: 100%;border-bottom:1px solid #ededed;
              button {
                // margin:0 5px;
                background: none;color:#f99970;
                svg {
                  fill: #f99970;vertical-align: -3px;
                }
              }
              .nfk {
                 border: 1px solid #c01d20;display: block;color: #c01d20;    width: 75px;height: 25px;border-radius: 5px;
              }
              .mgbtm {
                margin-bottom:5px;
              }
              &:last-child {
                border-bottom:0px;
              }
            }
          }
        }
      }
    }
  }
  .detail {
    width: 970px;margin:0 auto;text-align: right;margin-top:30px;
    .group-info {
      zoom:1;margin-bottom:10px;
      .lt {
        float:right;color:#474747;
      }
      .rt {
        float:right;width: 140px;
      }
      &:after {
        content: '';display: block;clear: both;
      }
      &:last-child {
        .lt {
          color:#ce2022;height: 30px;line-height: 30px;
        }
        .rt {
          color:#ce2022;font-weight: bold;font-size: 20px;
        }
      }
    }
  }
}
</style>
