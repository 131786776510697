<!-- 物流信息 -->
<template>
  <div class="logisticsinfo">
  	<div class="box">
  		<div class="top">
  			<div class="group-info">
  				<div class="lt">
  					送货方式：
  				</div>
  				<div class="rt">
  					普通快递
  				</div>
  			</div>
  			<div class="group-info">
  				<div class="lt">
  					快递单号：
  				</div>
  				<div class="rt">
  					123454656
  				</div>
  			</div>
  		</div>
  		<div class="cnt-logisticsinfo">
  			<!-- <el-timeline>
  				<el-timeline-item>
			      <div class="info">
			        <h6>已签收<p><span v-if='type==3'>2019-03-15/周五</span><span class='sp'>17:41:44</span></p><svg><use xlink:href="#iconwancheng"></use></svg></h6>
			        <p class="p">您的订单已由本人签收，感谢您在京东购物，欢迎再次光临。</p>
			      </div>
			    </el-timeline-item>
  				<el-timeline-item>
			      <div class="info">
			        <h6>派送中<p><span v-if='type==2'>2019-03-15/周五</span><span class='sp'>17:41:44</span></p><svg><use xlink:href="#icondengdaishouhuo"></use></svg></h6>
			        <p class="p">您的订单正在配送途中（配送员：李鹏飞，电话：18021662）请您准备签收，感谢您的耐心等待</p>
			      </div>
			    </el-timeline-item>
  				<el-timeline-item>
			      <div class="info">
			        <h6>运输中<p><span v-if='type==2'>2019-03-15/周五</span><span class='sp'>17:41:44</span></p><svg><use xlink:href="#iconwuliu"></use></svg></h6>
			        <p class="p">您的订单已进入第三方卖家仓库，准备出库</p>
			        <p class="p">您的订单已进入第三方卖家仓库，准备出库<span class='sp'>17:41:44</span></p>
			        <p class="p">您的订单已进入第三方卖家仓库，准备出库<span class='sp'>17:41:44</span></p>
			      </div>
			    </el-timeline-item>
  				<el-timeline-item>
			      <div class="info">
			        <h6>仓库处理中<p><span v-if='type==1'>2019-03-15/周五</span><span class='sp'>17:41:44</span></p><svg><use xlink:href="#icondengdaishouhuo"></use></svg></h6>
			        <p class="p">您的订单已进入第三方卖家仓库，准备出库</p>
			      </div>
			    </el-timeline-item>
			    <el-timeline-item>
			      <div class="info">
			        <h6>已下单<p><span v-if='type==0'>2019-03-15/周五</span><span class="sp">17:41:44</span></p><svg><use xlink:href="#icondaifukuan-wodedingdan"></use></svg></h6>
			        <p class="p">您提交了订单，请等待系统确认</p>
			      </div>
			    </el-timeline-item>
			</el-timeline> -->
			<el-timeline>
              <el-timeline-item v-if='logisticsData&&logisticsData.TracesList.length>0'>
                <div class="info">
                  <h6>{{logisticsData.state!=3?'运输中':'已签收'}}<p><span>{{logisticsData.TracesList[logisticsData.TracesList.length-1].AcceptTime | getTimet2}}</span><span class='sp'>{{ logisticsData.TracesList[logisticsData.TracesList.length-1].AcceptTime | getTimet3}}</span></p>
                    <svg v-show='logisticsData.state!=3'><use xlink:href="#iconyunshuzhong1"></use></svg>
                    <svg v-show='logisticsData.state==3'><use xlink:href="#iconyiqianshou"></use></svg>
                    <span class="svgs"></span>
                  </h6>
                  <!-- <p class="p" v-for='items,indexs in logisticsData.TracesList' :key='indexs'>您的订单已进入第三方卖家仓库，准备出库</p> -->
                  <!-- <p class="p">您的订单已进入第三方卖家仓库，准备出库<span class='sp'>17:41:44</span></p> -->
                  <p class="p" v-for='items,indexs in logisticsData.TracesList' :key='indexs'>{{items.AcceptStation}}<span v-show='indexs!=0' class='sp'>{{items.AcceptTime | getTimet3}}</span></p>
                </div>
              </el-timeline-item>
              <el-timeline-item  v-if='type>=1&&logisticsData1&&logisticsData1.length>0'>
                <div class="info">
                  <h6 >仓库处理中<p><span>{{logisticsData1[logisticsData1.length-1].OperateDate | getTimet1}}</span><span class="sp">{{logisticsData1[logisticsData1.length-1].OperateDate | getTimet4}}</span></p><svg><use xlink:href="#iconcangkuchulizhong"></use></svg><span class="svgs"></span></h6>
                      <p class="p" v-for='items,indexs in logisticsData1' :key='indexs'>{{items.msg}}<span v-show='indexs!=0'  class='sp'>{{items.OperateDate | getTimet4}}</span></p>
                </div>
              </el-timeline-item>
              <el-timeline-item v-if='type>=0'>
                <div class="info">
                  <h6>已下单<p><span>{{popData.OrderDate | getTimet1}}</span><span class="sp">{{popData.OrderDate | getTimet}}</span></p><svg><use xlink:href="#iconyixiadan"></use></svg><span class="svgs"></span></h6>
                  <p class="p">您提交了订单，请等待系统确认</p>
                </div>
              </el-timeline-item>
            </el-timeline>
  		</div>
  	</div>
  </div>
</template>
<script>
export default {
  name: 'logisticsinfo',
  props: {
    type: Number,
    popData:Object
  },
  data(){
  	return {
      logisticsData:null,
  		logisticsData1:null
  	}
  },
  methods:{
  },
  mounted(){
  	// 打开物流
	  // console.log(1)
	  this._axios({then:()=>{
	    this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainTraceInfoByOrderName?OrderName='+this.popData.OrderName,this.keys())
	    .then(res => {
	        if(res.data.Success){
	          this.logisticsData = res.data.Data
	        }
	      }, res => {
	        console.log('物流请求失败')
	      })
	  },exp:true,catch:()=>{
        var pms = this.$route.fullPath + '.html'
          this.$router.push({path:'/accountlogin',query:{redirect:pms}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
    this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainOrderStatusLog?OrderName='+this.popData.OrderName,this.keys())
        .then(res => {
            if(res.data.Success){
              this.logisticsData1 = res.data.Data
            }
          }, res => {
            console.log('物流请求失败')
          })
      },exp:true,catch:()=>{
          var pms = this.$route.fullPath + '.html'
          this.$router.push({path:'/accountlogin',query:{redirect:pms}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
  },
  filters:{
    imgreset(v,that){
      return that._src +v
    },
    timereset(v){
      if(v){
        return v.split('T')[0] + ' '+v.split('T')[1]
      }else{
        return ''
      }
    },
    getTimet(v){
      // 获取时分秒
      if(v){
        return v.split('T')[1]
      }else{
        return '';
      }
    },
    getTimet1(v){
      // 获取日期和星期
      if(v){
        var _dae = v.split('T')[0];
        var _days = ['周日','周一','周二','周三','周四','周五','周六'][new Date(v).getDay()];
        return v.split('T')[0]+'/'+_days
      }else{
        return '';
      }
    },
    getTimet2(v){
      // 物流-获取日期和星期
      if(v){
        var _dae = v.split(' ')[0];
        var _days = ['周日','周一','周二','周三','周四','周五','周六'][new Date(v).getDay()];
        return v.split(' ')[0]+'/'+_days
      }else{
        return '';
      }
    },
    getTimet3(v){
      // 物流-获取时分秒
      if(v){
        return v.split(' ')[1]
      }else{
        return '';
      }
    },
    getTimet4(v){
      // 物流-获取时分秒
      if(v){
        return v.split('T')[1]
      }else{
        return '';
      }
    },
  }
}
</script>
<style lang="scss">
  .cnt-logisticsinfo {
      padding:20px 0;padding-left:190px;min-height: 20px;max-height: 250px;overflow-y: auto;
      .info {
        h6 {
          position: relative;font-size: 12px;margin-bottom:5px;padding-top:4px;
          p {
            position: absolute;top:5px;left:-215px;font-weight: normal;font-size: 12px;text-align: right;width: 170px;
            span {
              font-size: 12px;
            }
            .sp {
              margin-left:15px;font-size: 12px;
            }
          }
          svg {
            fill: #797979;
            width: 24px;height: 24px;position: absolute;top:0px;left:-35px;z-index: 99;
          }
          .svgs {
            width: 24px;height: 24px;position: absolute;top:0px;left:-35px;z-index: 9;background: #fff;
          }
        }
        .p {
          font-size: 12px;margin-bottom:10px;position: relative;
          .sp {
            position: absolute;top:0px;left:-93px;width: 60px;display: block;font-size: 12px;
          }
        }
      }
      .el-timeline {
        .el-timeline-item {
          .el-timeline-item__node {
            width: 20px;height: 20px;left:-5px;display: none;
          }
          &:first-child {
            // .el-timeline-item__node {
            //   background: red;
            // }
            .info {
              h6 {
                svg {
                  fill: red;
                }
              }
            }
          }
        }
      }
    }
</style>
<style  lang="scss" scoped>
.logisticsinfo {
	background: #fff;padding-bottom:30px;
	.box {
		border:1px solid #eeeef0;width: 970px;margin:0 auto;
		.top {
			height: 58px;border-bottom:1px solid #eeeef0;padding-top:8px;
			.group-info {
				zoom:1;padding-left:20px;height: 25px;line-height: 25px;
				.lt {
					float:left;color:#bababa;
				}
				.rt {
					float:left;
				}
				&:after {
					display: block;content: "";clear: both;
				}
			}
		}
	}
	.cnt-logisticsinfo {
		// padding:20px 0;padding-left:190px;min-height: 20px;max-height: 250px;overflow-y: auto;
		// .info {
		// 	h6 {
		// 		position: relative;font-size: 12px;margin-bottom:5px;padding-top:4px;
		// 		p {
		// 			position: absolute;top:5px;left:-215px;font-weight: normal;font-size: 12px;text-align: right;width: 170px;
		// 			span {
		// 				font-size: 12px;
		// 			}
		// 			.sp {
		// 				margin-left:15px;font-size: 12px;
		// 			}
		// 		}
		// 		svg {
		// 			fill: #fff;
		// 			width: 15px;height: 15px;position: absolute;top:6px;left:-30px;
		// 		}
		// 	}
		// 	.p {
		// 		font-size: 12px;margin-bottom:10px;position: relative;
		// 		.sp {
		// 			position: absolute;top:0px;left:-93px;width: 60px;display: block;font-size: 12px;
		// 		}
		// 	}
		// }
		// /deep/.el-timeline {
		// 	.el-timeline-item {
		// 		.el-timeline-item__node {
		// 			width: 20px;height: 20px;left:-5px;
		// 		}
		// 		&:first-child {
		// 			.el-timeline-item__node {
		// 				background: red;
		// 			}
		// 		}
		// 	}
		// }
	}
}
</style>
