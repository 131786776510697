<template>
  <div class="ordertypes">
    <div class="box" v-if='type==0'>
      <h6>待付款</h6>
      <p class="p"><svg><use xlink:href="#iconshizhong"></use></svg>{{time | waitTime}}</p>
      <button class="now"  @click='paynoworder(popData)'>立即付款</button>
      <button @click='cancelorder(popData)' class="none">取消订单</button>
      <p class="p1">该订单会为您保留24小时（从下单之日算起），24小时之后如果还未付款，系统将自动取消该订单。</p>
    </div>
    <div class="box box1" v-if='type==1'>
      <h6>待发货</h6>
      <p class="p1">尊敬的客户，您的订单正在出库，请您耐心等待。</p>
    </div>
    <div class="box box2" v-if='type==2'>
      <h6>待收货</h6>
      <button @click='confirm' class="now">确认收货</button>
      <p class="p1">尊敬的客户，您的订单已出库，请您耐心等待。</p>
      <!-- <p class="p1">请收到货后，再确认收货，否则您可能钱货两空</p> -->
    </div>
    <div class="box box3" v-if='type==3&&popData.evaluateNum==0'>
      <h6>完成</h6>
      <!-- <p class="p" @click='sqsh'><svg><use xlink:href="#iconshouhouguanli"></use></svg>申请售后</p> -->
      <!-- <button class="now">去评价</button> -->
      <p class="p1">您的订单已完成</p>
      <!-- <p class="p1">该订单会为您保留24小时（从下单之日算起），24小时之后如果还未付款，系统将自动取消该订单。</p> -->
    </div>
    <div class="box box3" v-if='type==3&&popData.evaluateNum!=0'>
      <h6>完成</h6>
      <!-- <button class="now">评价详情</button> -->
      <p class="p1">您的订单已完成</p>
      <!-- <p class="p1">该订单会为您保留24小时（从下单之日算起），24小时之后如果还未付款，系统将自动取消该订单。</p> -->
    </div>
    <div class="box box4" v-if='type==4'>
      <h6>已取消</h6>
      <button @click='buyPro(popData)' class="now">立即购买</button>
      <p class="p1">您的订单已取消，所有优惠券将返还至您的微微定账户。</p>
    </div>
    <div class="box box4" v-if='type==5'>
      <h6>已退/换</h6>
      <button @click='buyPro(popData)' class="now">立即购买</button>
      <p class="p1">您的订单已退/换。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ordertypes',
  data(){
    return {
      remarks:1,//0未评价，1已评价
      nowDate:Date.now(),
      t:0,
      time:null,
      time1:null
    }
  },
  props: {
    type: Number,
    popData:Object,
  },
  methods:{
    paynoworder(i){
      // 立即付款
      this.$router.push({path:'/payment',query:{ordername:i.OrderName+'.html'}})
    },
    buyPro(item){
      // 再次购买
      // console.log(item)
      // return;
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/AgainBuy?orderName='+item.OrderName,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              // 返回购物车
              // console.log(this.buylist)
              for(let i in res.data.Data){
                res.data.Data[i].id = res.data.Data[i].jobID
                res.data.Data[i]._choice = true
              }
              window.sessionStorage.setItem('carlist',JSON.stringify(res.data.Data))
              this._router({exp:false,catch:()=>{
                // this.$router.push({path:'accountlogin',query:{redirect:'buycar'}})
                // this._open({path: "/accountlogin",query: {redirect:'buycar'}})
              },then:()=>{
                this.$router.push('/buycar.html')
              }})
            }
          }, res => {
            console.log('再次购买异常')
          })
      },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
    },
    confirm(){
      // 确认收货
      this.$MessageBox.confirm('请确认是否已收到货？','确认收货',{
        confirmButtonText: '确认',
        cancelButtonText: '关闭',
        customClass:'joinCar',
        center:true,
      }).then(() => {
        // console.log('确认收货')
      }).catch(() => {
        // console.log('关闭')
      });
      // end
    },
    sqsh(){
      // 申请售后

    },
    cancelorder(i){
      // 取消订单
      this.$MessageBox.confirm('请确认是否取消订单？','取消订单',{
        confirmButtonText: '确认',
        cancelButtonText: '关闭',
        customClass:'joinCar',
        center:true,
      }).then(() => {
        var CustomerID='';
        if(this.$store.state.user){
          if(this.$store.state.user.CustomerID){
            CustomerID = this.$store.state.user.CustomerID
          }
        }
        this._axios({then:()=>{
          this.axios.post(process.env.VUE_APP_URL+'UserCenter/cancelOrders?orderName='+i.OrderName,this.keys({token:this._token({method:'get'})}))
          .then(res => {
              if(res.data.Success){
                this.$emit('exportTyps')
                // this.$router.path({path:'/orderdetail',query:{StatusID:7,PayStatusID:0,ISDelivery:0}})
                this._router({then:()=>{
                  this.$router.push({path:'/orderdetail',query:{StatusID:7,PayStatusID:0,ISDelivery:0,OrderName:i.OrderName+'.html',}})
                }})
              }
            }, res => {
              console.log('系统异常')
            })
        },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
        // console.log('确认')
      }).catch(() => {
        // console.log('关闭')
      });
      // end
    }
  },
  mounted(){
    this.time = this.popData.OrderDate
    this.time1 = this.popData.OrderDate
    this.t = setInterval(()=>{
      this.nowDate = Date.now()
    },1000)
  },
  filters:{
    waitTime(v){
      // 24小时等待倒计时
      var _date = new Date(v).getTime()+86400000;
      var _nowDate = Date.now();
      var _waitDate = _date - _nowDate
      if(_waitDate>0){
        // console.log(_waitDate/1000/60/60%24)
        // 剩余0时28分
        return '剩余'+Math.floor(_waitDate/1000/60/60%24)+'时'+Math.floor(_waitDate/1000/60%60)+'分'+Math.floor(_waitDate/1000%60)+'秒';
      }else{
        return '超时';
      }
    }
  },
  watch:{
    nowDate(n,o){
      this.time = ''
      this.time = this.time1
    },
  },
  destroyed(){
    // 组件销毁清空定时变量
    clearInterval(this.t)
  }
}
</script>
<style lang="scss">
.joinCar {
    width: 340px;
   .el-message-box__header {
    margin-top:0px;padding:0px;height: 40px;line-height: 40px;background: #f3f3f3;margin-bottom:20px;
    .el-message-box__title {
      height: 40px;line-height: 40px;font-size: 18px;color:#8f929b;justify-content: flex-start;
      span {
        margin-left:10px;
      }
    }
   }
   .el-message-box__message {
    margin:10px 0px;
    svg {
      vertical-align: -10px;width: 30px;height: 30px;margin-right: 10px;fill: #8bc34a;
    }
   }
  .el-message-box__btns {
    button {
      &:hover {
        color:#333;background: rgba(0,0,0,0.08);border:1px solid #ccc;
      }
    }
    .el-button--primary {
      background: #f54d70;border:1px solid #f54d70;
      &:hover {
        color:#fff;background: #f54d70;opacity: 0.8;border:1px solid #f54d70;
      }
    }
  }
  .el-message-box__headerbtn {
    top:10px;
  }
}
</style>
<style  lang="scss" scoped>
.ordertypes {
  background: #fff;
  .box {
    margin-top:30px;
    h6 {
      font-size: 26px;font-weight: normal;color:#e5415c;text-align: center;
    }
    .p {
      text-align: center;color:#e5415c;margin-top:2px;
      svg {
        fill: #e5415c;vertical-align: -5px;margin-right: 3px;width: 20px;height: 20px;
      }
    }
    .now {
      width: 100px;height: 30px;border:1px solid #e5415c;background: none;display: block;margin:0 auto;border-radius: 5px;font-size: 14px;line-height: 25px;color:#e5415c;font-weight: bold;margin-top:15px;
      &:hover {
        opacity: 0.8;
      }
    }
    .none {
      display: block;margin:0 auto;background: none;font-size: 14px;color:#363636;margin-top:5px;
      &:hover {
        opacity: 0.8;
      }
    }
    .p1 {
      color:#8c8c8c;text-align: center;font-size: 14px;margin-top:20px;margin-bottom:30px;
    }
  }
  .box1 {
    h6 {
      color: #6a9d4e;
    }
  }
  .box2 {
    h6 {
      color: #6a9d4e;
    }
    .now {
      border-color:#313131;color:#313131;
    }
  }
  .box3 {
    h6 {
      color: #6a9d4e;
    }
    .p {
      color:#f99970;cursor: pointer;
      svg {
        fill: #f99970;
      }
    }
    .now {
      border-color:#313131;color:#313131;
    }
  }
  .box4 {
    h6 {
      color: #6a9d4e;
    }
    .p {
      color:#f99970;cursor: pointer;
      svg {
        fill: #f99970;
      }
    }
    // .now {
    //   border-color:#e5415c;color:#e5415c;
    // }
  }
}
</style>
