<!-- 详情步骤 -->
<template>
  <div class="detailstep">
    <div v-if='type<4' class="item" v-for='item,index in 4' :class='typeobj'>
      <div class="svg">
        <svg v-if='index==0'><use xlink:href="#icontijiaodingdan"></use></svg>
        <svg v-if='index==1'><use xlink:href="#iconfukuanchenggong"></use></svg>
        <!-- <svg v-if='index==2'><use xlink:href="#iconshangpinchuku"></use></svg> -->
        <svg v-if='index==2'><use xlink:href="#icondaishouhuo-wodedingdan"></use></svg>
        <span  v-if='index==3'><svg><use xlink:href="#iconwancheng"></use></svg></span>
      </div>
      <div class="round"></div>
      <div class="info">
        <h6 v-if='index==0'>1.提交订单</h6>
        <h6 v-if='index==1'>1.付款成功</h6>
        <!-- <h6 v-if='index==2'>1.商品出库</h6> -->
        <h6 v-if='index==2'>1.等待收货</h6>
        <h6 v-if='index==3'>1.完成</h6>
        <p v-if='index==0'>{{popData.OrderDate?popData.OrderDate:'' | timerest}}</p>
        <p v-if='index==1'>{{popData.PayDate?popData.PayDate:'' | timerest}}</p>
        <p v-if='index==2'>{{popData.DeliveryDate?popData.DeliveryDate:'' | timerest}}</p>
        <!-- <p v-if='index==3&&type==3'>{{popData.DeliveryDate?popData.DeliveryDate:'3'}}</p> -->
      </div>
    </div>
    <div v-if='type==5' class="item item1" v-for='item,index in 3' >
      <div class="svg">
        <svg v-if='index==0'><use xlink:href="#icontijiaodingdan"></use></svg>
        <svg v-if='index==1'><use xlink:href="#iconziyuan31"></use></svg>
        <span  v-if='index==2'><svg><use xlink:href="#iconwancheng"></use></svg></span>
      </div>
      <div class="round"></div>
      <div class="info">
        <h6 v-if='index==0'>1.提交申请</h6>
        <h6 v-if='index==1'>1.系统处理</h6>
        <h6 v-if='index==2'>1.完成</h6>
        <p v-if='index==0'>{{popData.OrderDate?popData.OrderDate:'' | timerest}}</p>
        <p v-if='index==1'>{{popData.ReturnDate?popData.ReturnDate:'' | timerest}}</p>
        <!-- <p v-if='index==0'>2019-10-12 20：10：10</p> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'detailstep',
  data(){
    return {
      typeobj:{
        type:this.type==0,
        type1:this.type==1,
        type2:this.type==2,
        type3:this.type==3,
        type4:this.type==4,
      }
    }
  },
  props: {
    type: Number,
    popData:Object,
  },
  methods:{
  },
  mounted(){
  },
  filters:{
    timerest(v){
      // 时间格式处理
      if(v){
        return v.split('T')[0]+' '+v.split('T')[1]
      }else{
        return ''
      }
    }
  }
}
</script>
<style  lang="scss" scoped>
.detailstep {
  background: #fff;zoom:1;padding-bottom:30px;
  .item {
    float:left;width: 150px;position: relative;margin-right: 80px;margin-top:35px;
    .svg {
      text-align: center;
      svg {
        width: 32px;height: 32px;
      }
      span {
        display: inline-block;width: 26px;height: 26px;background: #dddddd;border-radius: 50%;line-height: 28px;text-align: center;margin-top:1px;
        svg {
          width: 20px;height: 20px;fill: #fff;vertical-align: -6px;
        }
      }
    }
    .round {
      // d6424e
      width: 10px;height: 10px;border:3px solid #b6b6b6;border-radius: 50%;position: relative;margin:0 auto;margin-top:5px;
      &:after {
        content: '';display: block;position: absolute;width: 4px;height: 4px;background: #b6b6b6;top:3px;left:3px;border-radius: 50%;
      }
    }
    .info {
      h6 {
        // 1b1b1b
        font-size: 12px;color:#b6b6b6;font-weight: normal;text-align: center;margin-top:10px;
      }
      p {
        font-size: 10px;color:#b6b6b6;text-align: center;
      }
    }
    &:after {
      content: '';display: block;position: absolute;width: 200px;height: 2px;background: #b6b6b6;top:48px;left:90px;
    }
    &:first-child {
      margin-left:90px;
    }
    &:last-child {
      .round {
        margin-top:13px;
      }
      &:after {
        display: none;
      }
    }
  }
  .item1 {
    margin-right: 190px;
    .round {
      border-color:#d6424e;
      &:after {
        background: #d6424e;
      }
    }
    .info {
      h6 {
        color:#1b1b1b;
      }
    }
    &:after {
      width: 310px;background: #d6424e;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  .type {
    &:nth-child(1) {
      .round {
        border-color:#d6424e;
        &:after {
          background: #d6424e;
        }
      }
      .info {
        h6 {
          color:#1b1b1b;
        }
      }
      &:after {
        background: #d6424e;
      }
    }
  }
  .type1 {
    &:nth-child(1),&:nth-child(2) {
      .round {
        border-color:#d6424e;
        &:after {
          background: #d6424e;
        }
      }
      .info {
        h6 {
          color:#1b1b1b;
        }
      }
      &:after {
        background: #d6424e;
      }
    }
    &:nth-child(3) {
      .round {
        border-color:#d6424e;
        &:after {
          background: #d6424e;
        }
      }
      .info {
        h6 {
          color:#1b1b1b;
        }
      }
    }
  }
  .type2 {
    &:nth-child(1),&:nth-child(2) {
      .round {
        border-color:#d6424e;
        &:after {
          background: #d6424e;
        }
      }
      .info {
        h6 {
          color:#1b1b1b;
        }
      }
      &:after {
        background: #d6424e;
      }
    }
    &:nth-child(3) {
      .round {
        border-color:#d6424e;
        &:after {
          background: #d6424e;
        }
      }
      .info {
        h6 {
          color:#1b1b1b;
        }
      }
    }
  }
  .type3 {
    &:nth-child(1),&:nth-child(2),&:nth-child(3),&:nth-child(4),&:nth-child(5) {
      .round {
        border-color:#d6424e;
        &:after {
          background: #d6424e;
        }
      }
      .info {
        h6 {
          color:#1b1b1b;
        }
      }
      &:after {
        background: #d6424e;
      }
    }
  }
  &:after {
    content: '';display: block;clear: both;
  }
}
</style>
