<!-- 订单详情-标题 -->
<template>
  <div class="orderdetailtitle">
    <usercentertitle :title='"订单详情："+this._spf(this.$route.query.OrderName)'></usercentertitle>
    <button @click='back' class="back">返回订单列表<svg><use xlink:href="#iconziyuan12"></use></svg></button>
  </div>
</template>
<script>
import usercentertitle from '@/components/usercenter-title/usercenter-title.vue';//内容标题
export default {
  name: 'orderdetailtitle',
  components: {
    usercentertitle,
  },
  props: {
    msg: String
  },
  methods:{
    back(){
      // 返回我的订单列表
      this._router({then:()=>{
        this.$router.push('/usercenterorder.html')
      }})
    }
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.orderdetailtitle {
  background: #fff;height: 60px;position: relative;
  .back {
    background: none;color:#2054da;position: absolute;top:23px;right:20px;font-size: 12px;
    svg {
      width: 10px;height: 10px;fill: #2054da;margin-left:2px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
